import {
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  numberedEnv,
  muiProKey,
  localizeKey,
  sentryDSNKey,
  amplitudeStagingKey,
  procoreApiUrlStaging,
  isNumberedEnv,
} from "@src/runtime-config-base";
import { RuntimeConfig } from "@src/runtime-config-types";

export const runtimeConfig: RuntimeConfig = {
  environment: "staging",
  appRegion: "eu",
  appEnv: "eu-staging",
  urls: {
    apiBaseUrl: "https://core.api.staging.holobuilder.eu",
    sphereViewerUrl: "https://viewer.staging.holobuilder.eu",
    dashboard20Api: "https://sphere.staging.holobuilder.eu/api",
    hbWebEditorUrl: "https://app.staging.holobuilder.eu",
    progressApiUrl: "https://progress.api.staging.holobuilder.eu",
    sphereEntryPageUrl:
      numberedEnv === "dev1"
        ? "https://dev1.entry.staging.holobuilder.eu"
        : "https://entry.staging.holobuilder.eu",
    cookieManagerUrl: "",
    cookieManagerPreflyUrl: "",
    powerBIBaseUrl: "https://hb-powerbi-func-eu-staging.azurewebsites.net",
    projectApiUrl: "https://v2.project.api.staging.holobuilder.eu",
    hbDashboardUrl: "https://dashboard.staging.holobuilder.eu",
    registrationApiUrl: "https://cloud-reg.api.staging.holobuilder.eu",
    stagingAreaApiUrl: "https://staging-area.api.staging.holobuilder.eu",
    photogrammetryApiUrl: "https://hb-pre-alignment-batch-eu-staging.azurewebsites.net",
    uploadFrontDoorUrl: "https://files.staging.holobuilder.eu",
    uploadStorageUrl: "https://holobuildereucorestorage.blob.core.windows.net",
  },
  features: {
    muiProKey,
    localizeKey,
    sentryDSNKey,
    gtm: { auth: "", preview: "", id: "" },
    auth0: {
      domain: "login.eu.stg.farosphere.com",
      clientId: "iRaekFL1jv86gIakQI31pbVMwGLEwB5T",
    },
    amplitudeKey: amplitudeStagingKey,
  },
  integrations: {
    procoreApiUrl: procoreApiUrlStaging,
  },
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  mailSupportAddress: "support-eu+staging@holobuilder.com",
  isNumberedEnv,
  numberedEnv,
};
