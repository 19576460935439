import {
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  muiProKey,
  localizeKey,
  sentryDSNKey,
  amplitudeProdKey,
  procoreApiUrlProduction,
} from "@src/runtime-config-base";
import { RuntimeConfig } from "@src/runtime-config-types";

export const runtimeConfig: RuntimeConfig = {
  environment: "production",
  appRegion: "com",
  appEnv: "com-production",
  urls: {
    apiBaseUrl: "https://core.api.holobuilder.com",
    sphereViewerUrl: "https://viewer.holobuilder.com",
    dashboard20Api: "https://sphere.holobuilder.com/api",
    hbWebEditorUrl: "https://app.holobuilder.com",
    progressApiUrl: "https://progress.api.holobuilder.com",
    sphereEntryPageUrl: "https://entry.holobuilder.com",
    cookieManagerUrl:
      "https://cookies.holobuilder.com/cookie-manager/manager/latest",
    cookieManagerPreflyUrl:
      "https://cookies.holobuilder.com/cookie-manager/prefly/latest",
    powerBIBaseUrl: "https://hb-powerbi-func-com.azurewebsites.net",
    projectApiUrl: "https://v2.project.api.holobuilder.com",
    hbDashboardUrl: "https://dashboard.holobuilder.com",
    registrationApiUrl: "https://cloud-reg.api.holobuilder.com",
    stagingAreaApiUrl: "https://staging-area.api.holobuilder.com",
    photogrammetryApiUrl: "https://hb-pre-alignment-batch-com.azurewebsites.net",
    uploadFrontDoorUrl: "https://uploads.holobuilder.com",
    uploadStorageUrl: "https://holobuildercom.blob.core.windows.net",
  },
  features: {
    muiProKey,
    localizeKey,
    sentryDSNKey,
    gtm: { auth: "", preview: "", id: "" },
    auth0: {
      domain: "login.eu.farosphere.com",
      clientId: "VIgbt2dDKTFhsj2as6umqhKylJ01psTg",
    },
    amplitudeKey: amplitudeProdKey,
  },
  integrations: {
    procoreApiUrl: procoreApiUrlProduction,
  },
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  mailSupportAddress: "support@holobuilder.com",
  isNumberedEnv: false,
  numberedEnv: null,
};
